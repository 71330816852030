/**
 * Course view component.
 * @module components/theme/View/CourseView
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import { Toast } from '@plone/volto/components';

import { Button, Container, Grid } from 'semantic-ui-react';
import { map } from 'lodash';

import config from '@plone/volto/registry';

import {
  Api,
  getBaseUrl,
  getBlocksFieldname,
  getBlocksLayoutFieldname,
} from '@plone/volto/helpers';
import { EPIC_COURSE_REGISTRATION } from '@package/constants';
import { startEpic } from 'volto-epics-addon/helpers';
import { toast } from 'react-toastify';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
  registration: {
    id: 'registration',
    defaultMessage: 'Online registration instructions',
  },
  registrationDescriptionPart1: {
    id: 'registrationDescriptionPart1',
    defaultMessage:
      'Start the process by registering and creating a JYU user ID. Registration requires electronic identification (online banking identifiers or mobile ID). If you do not have Finnish online banking identifiers, you can use your passport or EU ID card for the identification process. Please note that you cannot use a Finnish passport or ID card for identification.',
  },
  registrationDescriptionPart2: {
    id: 'registrationDescriptionPart2',
    defaultMessage:
      'If you already have a JYU user ID, start the registration process by signing in with your email address and password.',
  },

  digicampus: {
    id: 'digicampus',
    defaultMessage: 'DigiCampus',
  },

  digicampusDescription: {
    id: 'digicampusDescription',
    defaultMessage:
      'You will find the study instructions from DigiCampus learning environment. You can sign into the e-learning environment with the JYU username and password, which you receive when you register for studies.',
  },

  moodle: {
    id: 'moodle',
    defaultMessage: 'Moodle',
  },

  moodleDescription: {
    id: 'moodleDescription',
    defaultMessage:
      'You will find the study instructions from Moodle. You can sign into the e-learning environment with the JYU username and password, which you receive when you register for studies.',
  },

  tim: {
    id: 'tim',
    defaultMessage: 'TIM',
  },

  timDescription: {
    id: 'timDescription',
    defaultMessage:
      'You will find the study instructions from TIM. You can sign into the e-learning environment with the JYU username and password, which you receive when you register for studies. ',
  },

  jyuonlinecourses: {
    id: 'jyuonlinecourses',
    defaultMessage: 'Jyu Online Courses learning platform',
  },

  jyuonlinecoursesDescription: {
    id: 'jyuonlinecoursesDescription',
    defaultMessage:
      'You will find the study instructions from JYU Online Courses learning platform. You can sign into the e-learning environment with the JYU username and password, which you receive when you register for studies.',
  },

  registrationInterval: {
    id: 'registrationInterval',
    defaultMessage: 'Registration:',
  },

  credits: {
    id: 'credits',
    defaultMessage: 'Credits:',
  },

  free: {
    id: 'courseViewFree',
    defaultMessage: 'Free of charge',
  },

  pay: {
    id: 'courseViewPay',
    defaultMessage: 'Pay',
  },

  moodleLoginInstructions: {
    id: 'Moodle_login_instructions',
    defaultMessage: 'Moodle login instructions',
  },

  moodleLoginInstructionsList1Part1: {
    id: 'Moodle_login_instructions_list1_part_1',
    defaultMessage: 'Choose',
  },

  moodleLoginInstructionsList1Part2: {
    id: 'Moodle_login_instructions_list1_part_2',
    defaultMessage: 'Go to your course',
  },

  moodleLoginInstructionsList2Part1: {
    id: 'Moodle_login_instructions_list2_part_1',
    defaultMessage: 'Choose',
  },

  moodleLoginInstructionsList2Part2: {
    id: 'Moodle_login_instructions_list2_part_2',
    defaultMessage: 'Login with JYU credentials',
  },

  moodleLoginInstructionsList3: {
    id: 'Moodle_login_instructions_list3',
    defaultMessage: 'Enter username: ',
  },

  moodleLoginInstructionsList4: {
    id: 'Moodle_login_instructions_list4',
    defaultMessage: 'Enter your password',
  },

  moodleLoginInstructionsList5Part1: {
    id: 'Moodle_login_instructions_list5_part_1',
    defaultMessage: 'Click the',
  },

  moodleLoginInstructionsList5Part2: {
    id: 'Moodle_login_instructions_list5_part_2',
    defaultMessage: 'Login',
  },

  moodleLoginInstructionsList5Part3: {
    id: 'Moodle_login_instructions_list5_part_3',
    defaultMessage: 'button',
  },

  timLoginInstructions: {
    id: 'TIM_login_instructions',
    defaultMessage: 'TIM login instructions',
  },

  timLoginInstructionsList1Part1: {
    id: 'Tim_login_instructions_list1_part_1',
    defaultMessage: 'Choose',
  },

  timLoginInstructionsList1Part2: {
    id: 'Tim_login_instructions_list1_part_2',
    defaultMessage: 'Go to your course',
  },

  timLoginInstructionsList2Part1: {
    id: 'Tim_login_instructions_list2_part_1',
    defaultMessage: 'Choose',
  },

  timLoginInstructionsList2Part2: {
    id: 'Tim_login_instructions_list2_part_2',
    defaultMessage: 'Kirjaudu sisään',
  },

  timLoginInstructionsList3Part1: {
    id: 'Tim_login_instructions_list3_part_1',
    defaultMessage: 'Choose',
  },

  timLoginInstructionsList3Part2: {
    id: 'Tim_login_instructions_list3_part_2',
    defaultMessage: 'Haka-kirjautuminen Jyväskylän yliopisto',
  },

  timLoginInstructionsList3Part3: {
    id: 'Tim_login_instructions_list3_part_3',
    defaultMessage: 'and click the',
  },

  timLoginInstructionsList3Part4: {
    id: 'Tim_login_instructions_list3_part_4',
    defaultMessage: 'Kirjaudu sisään',
  },

  timLoginInstructionsList3Part5: {
    id: 'Tim_login_instructions_list3_part_5',
    defaultMessage: 'button.',
  },

  timLoginInstructionsList4: {
    id: 'Tim_login_instructions_list4',
    defaultMessage: 'Enter username: ',
  },

  timLoginInstructionsList5: {
    id: 'Tim_login_instructions_list5',
    defaultMessage: 'Enter your password',
  },

  timLoginInstructionsList6: {
    id: 'Tim_login_instructions_list6',
    defaultMessage: 'Login',
  },

  onlinecoursesLoginInstructions: {
    id: 'OnlineCourses_login_instructions',
    defaultMessage: 'Online Courses login instructions',
  },

  onlinecoursesLoginInstructionsList1Part1: {
    id: 'OnlineCourses_login_instructions_list1_part_1',
    defaultMessage: 'Choose',
  },

  onlinecoursesLoginInstructionsList1Part2: {
    id: 'OnlineCourses_login_instructions_list1_part_2',
    defaultMessage: 'Go to your course',
  },

  onlinecoursesLoginInstructionsList2Part1: {
    id: 'OnlineCourses_login_instructions_list2_part_1',
    defaultMessage: 'To log in, click the ',
  },

  onlinecoursesLoginInstructionsList2Part2: {
    id: 'OnlineCourses_login_instructions_list2_part_2',
    defaultMessage: 'Log in',
  },

  onlinecoursesLoginInstructionsList2Part3: {
    id: 'OnlineCourses_login_instructions_list2_part_3',
    defaultMessage: 'button in the upper right corner.',
  },

  onlinecoursesLoginInstructionsList3Part1: {
    id: 'OnlineCourses_login_instructions_list3_part_1',
    defaultMessage: 'Choose ',
  },

  onlinecoursesLoginInstructionsList3Part2: {
    id: 'OnlineCourses_login_instructions_list3_part_2',
    defaultMessage: 'Login ',
  },

  onlinecoursesLoginInstructionsList4: {
    id: 'OnlineCourses_login_instructions_list4',
    defaultMessage: 'Enter username: ',
  },

  onlinecoursesLoginInstructionsList5: {
    id: 'OnlineCourses_login_instructions_list5',
    defaultMessage: 'Enter your password',
  },

  onlinecoursesLoginInstructionsList6: {
    id: 'OnlineCourses_login_instructions_list6',
    defaultMessage: 'Login',
  },

  digicampusLoginInstructions: {
    id: 'digicampus_login_instructions',
    defaultMessage: 'DigiCampus login instructions',
  },

  digicampusLoginInstructionsList1: {
    id: 'digicampus_login_instructions_list1',
    defaultMessage:
      'You will get the enrolment key by email before the course starts.',
  },

  digicampusLoginInstructionsList2Part1: {
    id: 'digicampus_login_instructions_list2_part1',
    defaultMessage: 'When you have the enrolment key, choose',
  },

  digicampusLoginInstructionsList2Part2: {
    id: 'digicampus_login_instructions_list2_part2',
    defaultMessage: 'Go to your course',
  },

  digicampusLoginInstructionsList3Part1: {
    id: 'digicampus_login_instructions_list3_part1',
    defaultMessage: 'Choose',
  },

  digicampusLoginInstructionsList3Part2: {
    id: 'digicampus_login_instructions_list3_part2',
    defaultMessage: 'Login with HAKA account',
  },

  digicampusLoginInstructionsList4: {
    id: 'digicampus_login_instructions_list4',
    defaultMessage: 'Enter username: ',
  },

  digicampusLoginInstructionsList5: {
    id: 'digicampus_login_instructions_list5',
    defaultMessage: 'Enter your password and login',
  },

  digicampusLoginInstructionsList6: {
    id: 'digicampus_login_instructions_list6',
    defaultMessage:
      'There is a text field in the upper right corner of the page where you can write the name of the course, click search',
  },

  digicampusLoginInstructionsList7: {
    id: 'digicampus_login_instructions_list7',
    defaultMessage: 'Choose your course',
  },

  digicampusLoginInstructionsList8Part1: {
    id: 'digicampus_login_instructions_list8_part1',
    defaultMessage: 'Add the enrolment key to the ',
  },

  digicampusLoginInstructionsList8Part2: {
    id: 'digicampus_login_instructions_list8_part2',
    defaultMessage: 'Self enrolment (Student)',
  },

  digicampusLoginInstructionsList8Part3: {
    id: 'digicampus_login_instructions_list8_part3',
    defaultMessage: ' -box and click ',
  },

  digicampusLoginInstructionsList8Part4: {
    id: 'digicampus_login_instructions_list8_part4',
    defaultMessage: 'Enrol me',
  },

  groupRegistrationHeading: {
    id: 'groupRegistrationHeading',
    defaultMessage: 'Registration to study groups',
  },

  groupRegistrationContentPart1: {
    id: 'groupRegistrationContentPart1',
    defaultMessage:
      'When you have registered for a course in the ILPO portal, please check if there are also demo groups/labs/home groups. If there are, please register in Sisu using these instructions: ',
  },
  groupRegistrationContentPart2: {
    id: 'groupRegistrationContentPart2',
    defaultMessage:
      'Sisu instructions for new students — Digital Services (jyu.fi)',
  },
  groupRegistrationContentLink1: {
    id: 'groupRegistrationContentLink1',
    defaultMessage:
      'https://www.jyu.fi/digipalvelut/en/guides/for-new-student/sisu-instructions-for-new-students',
  },
  groupRegistrationContentPart3: {
    id: 'groupRegistrationContentPart3',
    defaultMessage: ' ',
  },
  groupRegistrationContentLink2: {
    id: 'groupRegistrationContentLink2',
    defaultMessage: 'https://www.jyu.fi/digipalvelut/fi/ohjeet/sisu-ohjeet',
  },
  groupRegistrationContentPart4: {
    id: 'groupRegistrationContentPart4',
    defaultMessage:
      'If you have problems with registration, please get in touch with the Study services: ',
  },
  groupRegistrationContentLink3: {
    id: 'groupRegistrationContentLink3',
    defaultMessage: 'https://help.jyu.fi',
  },
  groupRegistrationContentPart5: {
    id: 'groupRegistrationContentPart5',
    defaultMessage: 'https://help.jyu.fi',
  },
  partnerInstitutionPlaceholder: {
    id: 'Partner_institution_palceholder',
    defaultMessage: 'Enter your enrolment secret',
  },
  partnerInstitutionSelection: {
    id: 'Partner_institution_selection',
    defaultMessage: 'Select your educational institution',
  },

  // Translate messages from middleware
  ytolOrganisationInvalidId: {
    id: 'ytolOrganisationInvalidId',
    defaultMessage:
      'Unfortunately, the Collaboration Institute you provided appears to be invalid.',
  },

  guardCodeInvalid: {
    id: 'guardCodeInvalid',
    defaultMessage:
      'Unfortunately, the guard code you provided for the Collaboration Institute appears to be invalid.',
  },

  sisuEnrolmentReservationFailedOnImport: {
    id: 'sisuEnrolmentReservationFailedOnImport',
    defaultMessage:
      'A temporary failure in enrolment reservation has occurred.',
  },

  sisuEnrolmentReservationFailedCourseFull: {
    id: 'sisuEnrolmentReservationFailedCourseFull',
    defaultMessage: 'Unfortunately, the course is full already.',
  },
  studyStructureTitle: {
    id: 'studyStructureTitle',
    defaultMessage: 'Study groups and meetings',
  },
  studyStructureSelectedAmount: {
    id: 'studyStructureSelectedAmount',
    defaultMessage: 'Number of study groups',
  },
  studyStructureLocation: {
    id: 'studyStructureLocation',
    defaultMessage: 'Location',
  },
  studyStructureTime: {
    id: 'studyStructureTime',
    defaultMessage: 'Time',
  },
  studyStructureNoLocation: {
    id: 'studyStructureNoLocation',
    defaultMessage: 'Location not given',
  },
  registrationMustSetGuardCode: {
    id: 'registrationMustSetGuardCode',
    defaultMessage: 'You must set the guard code to proceed.',
  },
  registrationMustSelectYtol: {
    id: 'registrationMustSelectYtol',
    defaultMessage: 'You must select the partner institution to proceed.',
  },
});

const QuotaState = Object.freeze({
  AVAILABLE: 'AVAILABLE',
  NEARING_CAPACITY: 'NEARING_CAPACITY',
  FULL: 'FULL',
});

const StudyStructureComponent = ({ studyStructure }) => {
  const intl = useIntl();

  return (
    <div>
      <h3>{intl.formatMessage(messages.studyStructureTitle)}</h3>
      {studyStructure.groupsets.map((groupset, index) => (
        <div key={index}>
          <strong>{groupset.name.toUpperCase()}</strong>
          <p>
            {intl.formatMessage(messages.studyStructureSelectedAmount)}
            {': '}
            {groupset.range.max === groupset.range.min
              ? groupset.range.max
              : `${groupset.range.min} - ${groupset.range.max}`}
          </p>
          {groupset.subgroups.map((subgroup, subIndex) => (
            <div key={subIndex}>
              <strong>{subgroup.name}</strong>
              {subgroup.events.map((event, eventIndex) => {
                // Format the date
                const dateOptions = {
                  weekday: 'short',
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                };
                const formattedDate = new Date(event.date).toLocaleDateString(
                  'fi-FI',
                  dateOptions,
                );

                // Format the time
                const startTime = event.start_time.substring(0, 5); // HH:MM
                const endTime = event.end_time.substring(0, 5); // HH:MM

                return (
                  <div key={eventIndex}>
                    <ul>
                      <li>
                        {intl.formatMessage(messages.studyStructureTime)}
                        {': '}
                        {formattedDate} klo {startTime}
                        {'–'}
                        {endTime}
                      </li>
                      <li>
                        {intl.formatMessage(messages.studyStructureLocation)}
                        {': '}
                        {event.location
                          ? event.location
                          : intl.formatMessage(
                              messages.studyStructureNoLocation,
                            )}
                      </li>
                    </ul>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

/**
 * Component to display the default view.
 * @function DefaultView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const DefaultView = ({ content }) => {
  const { token } = useSelector((state) => state.userSession);
  const [registeredUsername, setRegisteredUsername] = useState('');
  const [sisuEnrolmentCompleted, setSisuEnrolmentCompleted] = useState(true);
  const [sharedGuardCode, setSharedGuardCode] = useState('');
  const [selectedYtolToken, setSelectedYtolToken] = useState('');
  const [studyStructure, setStudyStructure] = useState({});

  const location = useLocation();
  const intl = useIntl();

  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);

  const hasYtolOrganisations = !!(
    (content.ytol_organisations?.length ?? 0) > 0
  );
  const hasGuardCode = !!content.ask_guard_code;

  const quotaState = Number.isInteger(content?.sisu_free_spots_left)
    ? content?.sisu_free_spots_left > 0
      ? QuotaState.NEARING_CAPACITY
      : QuotaState.FULL
    : QuotaState.AVAILABLE;

  const today = new Date().toISOString().split('T')[0];
  const registrationOpen =
    content.registration_start_date &&
    content.registration_end_date &&
    today >= content.registration_start_date &&
    today <= content.registration_end_date;

  const priceTag = !!parseFloat(content.price)
    ? intl.formatMessage(messages.pay)
    : intl.formatMessage(messages.free);

  const determineCourseType = (url) => {
    if (url.includes('moodle.jyu.fi')) {
      return { coursetypeTitle: 'JYU Moodle', coursetypeId: 'jyumoodle' };
    } else if (
      url.includes('onlinecourses.jyu.fi') ||
      url.includes('mooc-test')
    ) {
      return {
        coursetypeTitle: 'Online Courses',
        coursetypeId: 'onlinecourses',
      };
    } else if (url.includes('tim.jyu.fi')) {
      return { coursetypeTitle: 'TIM', coursetypeId: 'tim' };
    } else if (url.includes('digicampus.fi')) {
      return { coursetypeTitle: 'Digicampus', coursetypeId: 'digicampus' };
    }
    return { coursetypeTitle: '', coursetypeId: '' };
  };

  const { coursetypeTitle, coursetypeId } = determineCourseType(
    content.vle_course_id || '',
  );

  // Handle change in the dropdown
  const handleYtolOrganisationChange = (event) => {
    setSelectedYtolToken(event.target.value);
  };

  // Handle change in the inputfield
  const handleSharedGuardCodeChange = (event) => {
    setSharedGuardCode(event.target.value);
  };

  const handleRegistration = async () => {
    if (hasYtolOrganisations) {
      if (!selectedYtolToken || selectedYtolToken === '') {
        toast.error(
          <Toast
            error
            content={intl.formatMessage(messages.registrationMustSelectYtol)}
          />,
        );
        return;
      }
    }
    if (content.shared_guard_code) {
      if (!sharedGuardCode) {
        toast.error(
          <Toast
            error
            content={intl.formatMessage(messages.registrationMustSetGuardCode)}
          />,
        );
        return;
      }
    }

    await startEpic(EPIC_COURSE_REGISTRATION, {
      ytolOrganisation: selectedYtolToken || '',
      guardCode: sharedGuardCode || '',
      contentUid: content.UID,
    });
  };

  useEffect(() => {
    let isMounted = true;

    const api = new Api();
    let username;
    try {
      username = jwtDecode(token).sub;
    } catch (e) {}

    (async () => {
      if (username) {
        try {
          const response = await api.get('/@relations', {
            params: {
              target: content['UID'],
              query_source: `/students/${username.replace('@', '-40')}`,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          for (const item of response['relations']?.['course']?.['items'] ||
            []) {
            if (isMounted) {
              setRegisteredUsername(username.split('@')[0]);
            }
            const workflow = await api.get(
              new URL(item['source']['@id']).pathname + '/@workflow',
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            );
            if (
              workflow?.['chain']?.['sisu_enrolment_workflow']?.['state']?.[
                'id'
              ] === 'in_progress'
            ) {
              setSisuEnrolmentCompleted(false);
            }
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    })();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    const structure = JSON.parse(content?.sisu_study_structure || '{}');
    setStudyStructure(structure);
  }, [content]);

  return (
    <div id="course-view">
      <Container className="course-container">
        <div>
          <h1>{content.title}</h1>

          <div className="course-highlights">
            <div className="highlight">
              {moment(content.course_start_date).format('DD.MM.YYYY')} {' – '}
              {moment(content.course_end_date).format('DD.MM.YYYY')}
            </div>
            {coursetypeTitle ? (
              <div className="highlight">{coursetypeTitle}</div>
            ) : (
              ''
            )}
            <div className="highlight">{priceTag}</div>

            {content.organisation_name ? (
              <div className="highlight">{content.organisation_name}</div>
            ) : (
              ''
            )}

            {hasYtolOrganisations ? (
              <div className="highlight">
                <FormattedMessage
                  id="Partner_institution"
                  defaultMessage="Partner institution"
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        <Grid stackable relaxed>
          <Grid.Row>
            <Grid.Column width="12">
              <div>
                {content.description && <p>{content.description}</p>}
                <p>
                  <strong>{intl.formatMessage(messages.credits)}</strong>{' '}
                  {content.credits}
                </p>
                {content.text && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content.text.data,
                    }}
                  />
                )}
                {map(
                  content[blocksLayoutFieldname].items.filter(
                    (block) =>
                      content[blocksFieldname][block]['@type'] !== 'title',
                  ),
                  (block) => {
                    const Block =
                      config.blocks.blocksConfig[
                        content[blocksFieldname]?.[block]?.['@type']
                      ]?.['view'] || null;
                    return Block !== null ? (
                      <Block
                        key={block}
                        id={block}
                        properties={content}
                        data={content[blocksFieldname][block]}
                        path={getBaseUrl(location?.pathname || '')}
                      />
                    ) : (
                      <div key={block}>
                        {intl.formatMessage(messages.unknownBlock, {
                          block: content[blocksFieldname]?.[block]?.['@type'],
                        })}
                      </div>
                    );
                  },
                )}
                {studyStructure?.groupsets && (
                  <StudyStructureComponent studyStructure={studyStructure} />
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              width="12"
              style={{ alignItems: 'center', overflow: 'hidden' }}
            >
              {hasYtolOrganisations ? (
                <div className="YTOL-information-container">
                  <div className="registration">
                    <div className="Icon calendar-end"></div>

                    <div className="registration-info-container">
                      <p className="reigstration-info">
                        {intl.formatMessage(messages.registrationInterval)}{' '}
                      </p>
                      <p className="date">
                        {moment(content.registration_start_date).format(
                          'DD.MM.YYYY',
                        )}
                        &nbsp; - &nbsp;
                        {moment(content.registration_end_date).format(
                          'DD.MM.YYYY',
                        )}
                      </p>
                    </div>
                  </div>

                  <h2>
                    <FormattedMessage
                      id="Partner_institution_heading"
                      defaultMessage="Partner Institution Registration"
                    />
                  </h2>
                  <p>
                    <FormattedMessage
                      id="Partner_institution_description"
                      defaultMessage="Welcome to registration! This course is offered by a partner institution. Select your institution from the list and add the code you received from your educational institution in the field below to register for the course. At the beginning of the registration, you need to log in to Ilpo."
                    />
                  </p>
                </div>
              ) : (
                <></>
              )}

              <div
                className={`sides-container ${
                  hasGuardCode || hasYtolOrganisations
                    ? 'additional-actions'
                    : ''
                }`}
              >
                <div className="left-side">
                  {content?.sisu_has_quota && (
                    <>
                      {quotaState === QuotaState.AVAILABLE && (
                        <div className="quota-container quote-state-available">
                          <p>
                            <FormattedMessage
                              id="quota-state-available"
                              defaultMessage="Available spots"
                            />
                          </p>
                        </div>
                      )}
                      {quotaState === QuotaState.NEARING_CAPACITY && (
                        <div className="quota-container quota-state-nearing-capacity">
                          <p>
                            <FormattedMessage
                              id="quota-state-nearing-capacity"
                              defaultMessage="Course is nearing capacity"
                            />
                          </p>
                        </div>
                      )}
                      {quotaState === QuotaState.FULL && (
                        <div className="quota-container quota-state-full">
                          <p>
                            <FormattedMessage
                              id="quota-state-full"
                              defaultMessage="Course is full or almost full"
                            />
                          </p>
                        </div>
                      )}
                    </>
                  )}
                  {registeredUsername ? (
                    <div className="enrolment-badge">
                      <svg
                        width="70"
                        height="70"
                        viewBox="0 0 70 70"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M67.0832 35.0005L59.9665 26.8922L60.9582 16.1589L50.429 13.7672L44.9165 4.49219L34.9998 8.75052L25.0832 4.49219L19.5707 13.7672L9.0415 16.1297L10.0332 26.863L2.9165 35.0005L10.0332 43.1089L9.0415 53.8714L19.5707 56.263L25.0832 65.538L34.9998 61.2505L44.9165 65.5089L50.429 56.2339L60.9582 53.8422L59.9665 43.1089L67.0832 35.0005ZM29.1665 49.5839L17.4998 37.9172L21.6123 33.8047L29.1665 41.3297L48.3873 22.1089L52.4998 26.2505L29.1665 49.5839Z"
                          fill="#C29A5B"
                        />
                      </svg>{' '}
                      <p>
                        {!sisuEnrolmentCompleted ? (
                          <FormattedMessage
                            id="SisuCourseEnrolmentInProgress"
                            defaultMessage="Your enrolment is being processed"
                          />
                        ) : (
                          <FormattedMessage
                            id="Enrolled"
                            defaultMessage="You are enrolled"
                          />
                        )}
                      </p>
                    </div>
                  ) : (
                    <>
                      {!hasYtolOrganisations && (
                        <div className="info">
                          <div className="registration">
                            <div className="Icon calendar-end"></div>

                            <div className="registration-info-container">
                              <p className="reigstration-info">
                                {intl.formatMessage(
                                  messages.registrationInterval,
                                )}{' '}
                              </p>
                              <p>
                                {moment(content.registration_start_date).format(
                                  'DD.MM.YYYY',
                                )}
                                &nbsp; - &nbsp;
                                {moment(content.registration_end_date).format(
                                  'DD.MM.YYYY',
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="price">
                            <p>{content.price} €</p>
                          </div>
                        </div>
                      )}
                      {(hasGuardCode || hasYtolOrganisations) && (
                        <div className="info">
                          <div className="additional-actions-container">
                            {hasYtolOrganisations && (
                              <select
                                id="titleDropdown"
                                onChange={handleYtolOrganisationChange}
                                onBlur={handleYtolOrganisationChange}
                              >
                                <option value="">
                                  {intl.formatMessage(
                                    messages.partnerInstitutionSelection,
                                  )}
                                </option>
                                {content.ytol_organisations.map(
                                  (item, index) => (
                                    <option key={index} value={item.token}>
                                      {item.title}
                                    </option>
                                  ),
                                )}
                              </select>
                            )}
                            {hasGuardCode && (
                              <input
                                type="text"
                                id="sharedGuardCode"
                                value={sharedGuardCode}
                                onChange={handleSharedGuardCodeChange}
                                onBlur={handleSharedGuardCodeChange}
                                placeholder={intl.formatMessage(
                                  messages.partnerInstitutionPlaceholder,
                                )}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className="right-side">
                  {registeredUsername ? (
                    <div className="moodle-link-container">
                      <div className="moodle-link">
                        <a
                          className="ui button primary"
                          style={{
                            textAlign: 'center',
                            fontSize: '1.5rem',
                            boxShadow: 'none',
                            borderRadius: '5px',
                            margin: '0',
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={content.vle_course_id}
                        >
                          <FormattedMessage
                            id="Go to your course"
                            defaultMessage="Go to your course"
                          />
                          <svg
                            width="35"
                            height="34"
                            viewBox="0 0 35 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.8335 15.5826V18.416H23.3335L15.3127 26.2076L17.3835 28.2193L28.9335 16.9993L17.3835 5.7793L15.3127 7.79096L23.3335 15.5826H5.8335Z"
                              fill="#002957"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  ) : hasYtolOrganisations ? (
                    <div className="course-registration-button-container ytol">
                      <Button
                        className="ui button ytol sign-up"
                        disabled={!registrationOpen}
                        onClick={handleRegistration}
                      >
                        <FormattedMessage
                          id="Course_registration_button_text"
                          defaultMessage="Register"
                        />
                      </Button>
                    </div>
                  ) : (
                    <div className="course-registration-button-container">
                      <Button
                        className="ui button sign-up"
                        disabled={!registrationOpen}
                        onClick={handleRegistration}
                      >
                        <FormattedMessage
                          id="Course_registration_button_text"
                          defaultMessage="Register"
                        />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Grid.Column>
            <Grid.Column
              width="6"
              style={{ alignItems: 'center', overflow: 'hidden' }}
            ></Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <div
        style={{
          backgroundColor: '#F6F7F7',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '1.2rem',
          marginTop: 'auto',
        }}
      >
        <div className="ui container information-footer">
          <div className="row footer-login-instructions">
            {registeredUsername ? (
              <div className="login-instructions">
                <div className="image-field">
                  <svg
                    width="156"
                    height="155"
                    viewBox="0 0 156 155"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M78.0002 154.875C120.733 154.875 155.375 120.233 155.375 77.5002C155.375 34.7671 120.733 0.125 78.0002 0.125C35.2671 0.125 0.625 34.7671 0.625 77.5002C0.625 120.233 35.2671 154.875 78.0002 154.875Z"
                      fill="#002957"
                    />
                    <path
                      d="M110.056 111.214H45.9449C44.7726 111.213 43.6486 110.747 42.8197 109.918C41.9908 109.089 41.5246 107.965 41.5234 106.793V68.105C41.5247 66.9328 41.9909 65.8089 42.8198 64.98C43.6487 64.1511 44.7726 63.6848 45.9449 63.6836H110.056C111.228 63.6848 112.352 64.1511 113.181 64.98C114.01 65.8089 114.476 66.9328 114.477 68.105V106.793C114.476 107.965 114.01 109.089 113.181 109.918C112.352 110.747 111.228 111.213 110.056 111.214ZM45.9449 68.1051V106.793H110.059L110.056 68.105L45.9449 68.1051Z"
                      fill="white"
                    />
                    <path
                      d="M97.8965 68.1055H58.1035V50.4197C58.1035 37.6099 66.4714 28.3125 78 28.3125C89.5286 28.3125 97.8965 37.6099 97.8965 50.4197L97.8965 68.1055ZM62.525 63.6841H93.4751V50.4197C93.4751 40.0067 87.1117 32.734 78.0001 32.734C68.8884 32.734 62.525 40.0068 62.525 50.4197L62.525 63.6841Z"
                      fill="white"
                    />
                    <path
                      d="M78.0001 90.7648C80.442 90.7648 82.4214 88.7852 82.4214 86.3433C82.4214 83.9014 80.442 81.9219 78.0001 81.9219C75.5582 81.9219 73.5786 83.9014 73.5786 86.3433C73.5786 88.7852 75.5582 90.7648 78.0001 90.7648Z"
                      fill="white"
                    />
                    <path
                      d="M78.0002 154.875C120.733 154.875 155.375 120.233 155.375 77.5002C155.375 34.7671 120.733 0.125 78.0002 0.125C35.2671 0.125 0.625 34.7671 0.625 77.5002C0.625 120.233 35.2671 154.875 78.0002 154.875Z"
                      fill="#002957"
                    />
                    <path
                      d="M110.056 111.214H45.9449C44.7726 111.213 43.6486 110.747 42.8197 109.918C41.9908 109.089 41.5246 107.965 41.5234 106.793V68.105C41.5247 66.9328 41.9909 65.8089 42.8198 64.98C43.6487 64.1511 44.7726 63.6848 45.9449 63.6836H110.056C111.228 63.6848 112.352 64.1511 113.181 64.98C114.01 65.8089 114.476 66.9328 114.477 68.105V106.793C114.476 107.965 114.01 109.089 113.181 109.918C112.352 110.747 111.228 111.213 110.056 111.214ZM45.9449 68.1051V106.793H110.059L110.056 68.105L45.9449 68.1051Z"
                      fill="white"
                    />
                    <path
                      d="M97.8965 68.1055H58.1035V50.4197C58.1035 37.6099 66.4714 28.3125 78 28.3125C89.5286 28.3125 97.8965 37.6099 97.8965 50.4197L97.8965 68.1055ZM62.525 63.6841H93.4751V50.4197C93.4751 40.0067 87.1117 32.734 78.0001 32.734C68.8884 32.734 62.525 40.0068 62.525 50.4197L62.525 63.6841Z"
                      fill="white"
                    />
                    <path
                      d="M78.0001 90.7648C80.442 90.7648 82.4214 88.7852 82.4214 86.3433C82.4214 83.9014 80.442 81.9219 78.0001 81.9219C75.5582 81.9219 73.5786 83.9014 73.5786 86.3433C73.5786 88.7852 75.5582 90.7648 78.0001 90.7648Z"
                      fill="white"
                    />
                  </svg>
                </div>

                <div className="information-field">
                  <div className="moodle-login-instructions">
                    {coursetypeId === 'jyumoodle' && (
                      <>
                        <h2>
                          {intl.formatMessage(messages.moodleLoginInstructions)}
                        </h2>
                        <ol>
                          <li>
                            {intl.formatMessage(
                              messages.moodleLoginInstructionsList1Part1,
                            )}{' '}
                            <strong>
                              {intl.formatMessage(
                                messages.moodleLoginInstructionsList1Part2,
                              )}
                            </strong>
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.moodleLoginInstructionsList2Part1,
                            )}{' '}
                            <strong>
                              {intl.formatMessage(
                                messages.moodleLoginInstructionsList2Part2,
                              )}
                            </strong>
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.moodleLoginInstructionsList3,
                            )}
                            <strong>
                              <code>{registeredUsername}</code>
                            </strong>
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.moodleLoginInstructionsList4,
                            )}
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.moodleLoginInstructionsList5Part1,
                            )}{' '}
                            <strong>
                              {intl.formatMessage(
                                messages.moodleLoginInstructionsList5Part2,
                              )}
                            </strong>{' '}
                            {intl.formatMessage(
                              messages.moodleLoginInstructionsList5Part3,
                            )}
                          </li>
                        </ol>
                      </>
                    )}
                    {coursetypeId === 'tim' && (
                      <>
                        <h2>
                          {intl.formatMessage(messages.timLoginInstructions)}
                        </h2>
                        <ol>
                          <li>
                            {intl.formatMessage(
                              messages.timLoginInstructionsList1Part1,
                            )}{' '}
                            <strong>
                              {intl.formatMessage(
                                messages.timLoginInstructionsList1Part2,
                              )}
                            </strong>
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.timLoginInstructionsList2Part1,
                            )}{' '}
                            <strong>
                              {intl.formatMessage(
                                messages.timLoginInstructionsList2Part2,
                              )}
                            </strong>
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.timLoginInstructionsList3Part1,
                            )}{' '}
                            <strong>
                              {' '}
                              {intl.formatMessage(
                                messages.timLoginInstructionsList3Part2,
                              )}{' '}
                            </strong>
                            {intl.formatMessage(
                              messages.timLoginInstructionsList3Part3,
                            )}{' '}
                            <strong>
                              {intl.formatMessage(
                                messages.timLoginInstructionsList3Part4,
                              )}
                            </strong>{' '}
                            {intl.formatMessage(
                              messages.timLoginInstructionsList3Part5,
                            )}
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.timLoginInstructionsList4,
                            )}
                            <strong>
                              <code>{registeredUsername}</code>
                            </strong>
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.timLoginInstructionsList5,
                            )}
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.timLoginInstructionsList6,
                            )}
                          </li>
                        </ol>
                      </>
                    )}
                    {coursetypeId === 'onlinecourses' && (
                      <>
                        <h2>
                          {intl.formatMessage(
                            messages.onlinecoursesLoginInstructions,
                          )}
                        </h2>
                        <ol>
                          <li>
                            {intl.formatMessage(
                              messages.onlinecoursesLoginInstructionsList1Part1,
                            )}{' '}
                            <strong>
                              {intl.formatMessage(
                                messages.onlinecoursesLoginInstructionsList1Part2,
                              )}
                            </strong>
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.onlinecoursesLoginInstructionsList2Part1,
                            )}{' '}
                            <strong>
                              {intl.formatMessage(
                                messages.onlinecoursesLoginInstructionsList2Part2,
                              )}
                            </strong>{' '}
                            {intl.formatMessage(
                              messages.onlinecoursesLoginInstructionsList2Part3,
                            )}
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.onlinecoursesLoginInstructionsList3Part1,
                            )}{' '}
                            <strong>
                              {intl.formatMessage(
                                messages.onlinecoursesLoginInstructionsList3Part2,
                              )}
                            </strong>
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.onlinecoursesLoginInstructionsList4,
                            )}
                            <strong>
                              <code>{registeredUsername}</code>
                            </strong>
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.onlinecoursesLoginInstructionsList5,
                            )}
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.onlinecoursesLoginInstructionsList6,
                            )}
                          </li>
                        </ol>
                      </>
                    )}
                    {coursetypeId === 'digicampus' && (
                      <>
                        <h2>
                          {intl.formatMessage(
                            messages.digicampusLoginInstructions,
                          )}
                        </h2>
                        <ol>
                          <li>
                            {intl.formatMessage(
                              messages.digicampusLoginInstructionsList1,
                            )}
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.digicampusLoginInstructionsList2Part1,
                            )}{' '}
                            <strong>
                              {intl.formatMessage(
                                messages.digicampusLoginInstructionsList2Part2,
                              )}
                            </strong>{' '}
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.digicampusLoginInstructionsList3Part1,
                            )}{' '}
                            <strong>
                              {intl.formatMessage(
                                messages.digicampusLoginInstructionsList3Part2,
                              )}
                            </strong>
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.digicampusLoginInstructionsList4,
                            )}
                            <strong>
                              <code>{registeredUsername}</code>
                            </strong>
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.digicampusLoginInstructionsList5,
                            )}
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.digicampusLoginInstructionsList6,
                            )}
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.digicampusLoginInstructionsList7,
                            )}
                          </li>
                          <li>
                            {intl.formatMessage(
                              messages.digicampusLoginInstructionsList8Part1,
                            )}
                            <strong>
                              {intl.formatMessage(
                                messages.digicampusLoginInstructionsList8Part2,
                              )}
                            </strong>
                            {intl.formatMessage(
                              messages.digicampusLoginInstructionsList8Part3,
                            )}
                            <strong>
                              {intl.formatMessage(
                                messages.digicampusLoginInstructionsList8Part4,
                              )}
                            </strong>
                          </li>
                        </ol>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>

          <div className="row registration-instructions">
            <div className="image-field"></div>
            <div className="text-field">
              <h2>{intl.formatMessage(messages.registration)}</h2>
              <p>{intl.formatMessage(messages.registrationDescriptionPart1)}</p>
              <p>{intl.formatMessage(messages.registrationDescriptionPart2)}</p>
            </div>
          </div>
          <div className="row learning-platform">
            {coursetypeId === 'jyumoodle' && (
              <>
                <div className="image-field image-field-jyumoodle"></div>
                <div className="text-field">
                  <h2>{intl.formatMessage(messages.moodle)}</h2>
                  <p>{intl.formatMessage(messages.moodleDescription)}</p>
                </div>
              </>
            )}
            {coursetypeId === 'tim' && (
              <>
                <div className="image-field image-field-tim"></div>
                <div className="text-field">
                  <h2>{intl.formatMessage(messages.tim)}</h2>
                  <p>{intl.formatMessage(messages.timDescription)}</p>
                </div>
              </>
            )}
            {coursetypeId === 'onlinecourses' && (
              <>
                <div className="image-field image-field-onlinecourses"></div>
                <div className="text-field">
                  <h2>{intl.formatMessage(messages.jyuonlinecourses)}</h2>
                  <p>
                    {intl.formatMessage(messages.jyuonlinecoursesDescription)}
                  </p>
                </div>
              </>
            )}
            {coursetypeId === 'digicampus' && (
              <>
                <div className="image-field image-field-digicampus"></div>
                <div className="text-field">
                  <h2>{intl.formatMessage(messages.digicampus)}</h2>
                  <p>{intl.formatMessage(messages.digicampusDescription)}</p>
                </div>
              </>
            )}
          </div>
          {content.group_registration === true && (
            <>
              <div className="row group-registration-row">
                <div className="image-field image-field-group-registration"></div>
                <div className="text-field">
                  <h2>
                    {intl.formatMessage(messages.groupRegistrationHeading)}
                  </h2>
                  <p>
                    {intl.formatMessage(messages.groupRegistrationContentPart1)}
                    <a
                      href={intl.formatMessage(
                        messages.groupRegistrationContentLink1,
                      )}
                    >
                      {intl.formatMessage(
                        messages.groupRegistrationContentPart2,
                      )}
                    </a>
                    {intl.locale === 'fi' && (
                      <>
                        {intl.formatMessage(
                          messages.groupRegistrationContentPart3,
                        )}
                        <a
                          href={intl.formatMessage(
                            messages.groupRegistrationContentLink2,
                          )}
                        >
                          {intl.formatMessage(
                            messages.groupRegistrationContentLink2,
                          )}
                        </a>
                      </>
                    )}
                    {intl.formatMessage(messages.groupRegistrationContentPart4)}
                    <a
                      href={intl.formatMessage(
                        messages.groupRegistrationContentLink3,
                      )}
                    >
                      {intl.formatMessage(
                        messages.groupRegistrationContentPart5,
                      )}
                    </a>
                    .
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
DefaultView.propTypes = {
  /**
   * Content of the object
   */
  content: PropTypes.shape({
    /**
     * Title of the object
     */
    title: PropTypes.string,
    /**
     * Description of the object
     */
    description: PropTypes.string,
    /**
     * Text of the object
     */
    text: PropTypes.shape({
      /**
       * Data of the text of the object
       */
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default DefaultView;
