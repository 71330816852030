// import { EPIC_PORTAL_COURSE_SISU_UPDATE } from '@package/constants';
// import { startEpic } from 'volto-epics-addon/helpers';
import {
  CREATE_CONTENT,
  UPDATE_CONTENT,
} from '@plone/volto/constants/ActionTypes';

const triggerSisuUpdateFlow = async (contentUid) => {
  // await startEpic(
  //   EPIC_PORTAL_COURSE_SISU_UPDATE,
  //   {
  //     contentUid: contentUid,
  //   },
  //   false,
  // );
};

const contentMiddleware = (store) => (next) => (action) => {
  if (action.type === CREATE_CONTENT + '_SUCCESS') {
    const contentUid = action?.result?.UID;
    if (!contentUid) {
      return next(action);
    }
    (async () => {
      await triggerSisuUpdateFlow(contentUid);
    })();
  }
  if (action.type === UPDATE_CONTENT + '_SUCCESS') {
    const contentUid = store.getState()?.content?.data?.UID;
    if (!contentUid) {
      return next(action);
    }
    (async () => {
      await triggerSisuUpdateFlow(contentUid);
    })();
  }
  return next(action);
};

export default contentMiddleware;
